import React, { useEffect } from 'react';
import { HubspotFormWrapper } from './HubspotForm.styles';

const HubSpotFormPopup = ({ isVisible, onClose }) => {

  const popupClass = isVisible ? 'hubspot-form-popup visible' : 'hubspot-form-popup';

  return (
    <HubspotFormWrapper className={popupClass}>
      <div className="hubspot-form-overlay" onClick={onClose}></div>
      <div className="hubspot-form-content">
        <div className="form-title">
          <h2 className="h5 text-white m-0 text-center">
            Request your FREE Payment Optimisation Assessment!
          </h2>
        </div>
        <button className="close-button" onClick={onClose}></button>
        <div id="hubspotForm"></div>
      </div>
    </HubspotFormWrapper>
  );
};

export default HubSpotFormPopup;

import React ,{useEffect, useRef} from "react";
import { CurrencySelectorWrapper } from "./CurrencySelector.styles";

const CurrencySelector = ({onRadioChange}) => {

    const defaultValue = useRef(null);

    useEffect(() => {
        defaultValue.current.checked = true;
    }, []);

    return(
        <CurrencySelectorWrapper>
            <div className="select-currency-text">
                Select your preferred currency:
            </div>
            <div className="current-options">
                <div className="currency-option">
                    <input type="radio" name="currencySelector" id="USD" value="$" onChange={(e) => onRadioChange(e.target.value)} ref={defaultValue}/>
                    <label htmlFor="USD">USD</label>    
                </div>
                <div className="currency-option">
                    <input type="radio" name="currencySelector" id="EUR" value="€" onChange={(e) => onRadioChange(e.target.value)}/>
                    <label htmlFor="EUR">EUR</label>    
                </div>
                <div className="currency-option">
                    <input type="radio" name="currencySelector" id="GBP" value="£" onChange={(e) => onRadioChange(e.target.value)}/>
                    <label htmlFor="GBP">GBP</label>     
                </div>
            </div>
        </CurrencySelectorWrapper>
    )
};

export default CurrencySelector;
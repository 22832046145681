import styled from "styled-components";
import { colors } from "../../../../../utils/styles";

export const FieldDescriptionPopupWrapper = styled.div`
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;

   .info-toggle{
        width: 24px;
        height: 24px;
        border-radius: 50%;
        cursor: pointer;
        background-color: ${colors.secondaryBlue2};
        position: relative;

        &.active{
            outline: 3px solid rgba(85, 186, 163, 0.40);
        }

        img{
            position: absolute;
            top: 0;
        }
    }

    .field-description{
        top: 36px;
        position: absolute;
        width: 440px;
        padding: 24px 40px 40px;
        background-color: #FFF;
        border-radius: 8px;
        border: 1px solid #CECECE;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
        z-index: 99;
        left: 50%;
        transform: translate(-50%);
        color: ${colors.primaryBlue};

        @media screen and (max-width: 500px) {
            padding: 25px;
        }

        .description-header{
            font-weight: 600;
            margin-bottom: 20px;
        }

        p:last-child{
            margin-bottom: 0;
        }

        @media screen and (max-width: 991px) {
            transform: unset;
            left: unset;
            right: calc(100% + 24px);
            top: 0;
        }
        @media screen and (max-width: 500px) {
            right: 0;
            top: 36px;
            width: calc(100vw - 48px);
            min-width: 272px;
        }
    }

`
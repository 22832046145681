import styled from "styled-components";

export const DisclaimerWrapper = styled.section`

    .container{
        background: linear-gradient(180deg, rgba(143, 197, 234, 0.3) 0%, rgba(110, 247, 218, 0.3) 100%);
        border-radius: 15px;

        p{
            font-size: 0.875em;
            line-height: 1.5em;

            &:last-child{
                margin-bottom: 0;
            }
        }
    }
`
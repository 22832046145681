import styled from "styled-components";
import { colors } from "../../../../../utils/styles";

export const HubspotFormWrapper = styled.div`
    position: fixed;
    z-index: 999999;
    top: 0;
    height: 0;
    width: 0;
    overflow: hidden;

    &.visible{
        overflow: unset;
        width: 100vw;
        height: 100vh;
    }

    .hubspot-form-overlay{
        position: relative;
        top: 0;
        background: rgba(34, 34, 34, 0.50);
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .hubspot-form-content{
        position: absolute;
        max-width: 900px;
        width: calc(100% - 48px);
        margin: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #FFF;
        border-radius: 8px;
        max-height: calc(100% - 48px);
        min-width: 272px;

        @media screen and (max-width: 767px), screen and (max-height: 767px) {
            overflow-y: scroll;
        }

        button.close-button{
            position: absolute;
            background-color: unset;
            height: 20px;
            width: 20px;
            border: unset;
            top: 15px;
            right: 15px;

            &::before,
            &::after {
                content: '';
                position: absolute;
                width: 2px;
                height: 20px;
                background-color: #FFF;
                bottom: 1px;
                left: 9px;
                transform: translateX(-50%);
            }

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }
        }

        .form-title{
            background-color: ${colors.secondaryBlue3};
            padding: 25px 35px;
            border-radius: 8px 8px 0 0;
            
            @media (max-width: 400px) {
                padding: 30px 20px;
            }
        }

        #hubspotForm{
            font-family: "Poppins";
            color: #33475b;
            font-size: 14px;
            padding: 50px 35px;

            @media (max-width: 400px) {
                padding: 50px 20px;
            }

            form{
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px 30px;
                position: relative;

                .legal-consent-container, .hs_submit.hs-submit{
                    grid-column: span 2;
                }

                @media screen and (max-width: 767px) {
                    grid-template-columns: 1fr;

                    .legal-consent-container, .hs_submit.hs-submit{
                        grid-column: span 1;
                    }
                }

                .cookie-reset-container{
                    position: absolute;
                    top: -25px;
                    right: 0;

                    @media (max-width: 400px) {
                        position: absolute;
                        top: -40px;
                        width: 100%;
                        text-align: center;
                    }
                    
                    a{
                        background-color: rgb(85, 185, 163);
                        padding: 6px 10px;
                        color: rgb(255, 255, 255);
                        box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 2px 0px;
                        font-weight: bold;
                        display: inline-block;
                        transition: scale, linear 0.2s;
                        margin-left: 10px;

                        &:hover {
                            scale: 1.03;
                        }
                    }
                }

                input[type="text"], input[type="email"]{
                    background-color: #f5f8fa;
                    border: 1px solid #cad5e2;
                    border-radius: 3px;
                    display: inline-block;
                    width: 100%;
                    height: 40px;
                    padding: 9px 10px;
                    box-sizing: border-box;
                }

                .hs-input{
                    &.invalid.error{
                        border-color: #c87872;
                    }
                    &:focus {
                        border-color: rgba(82,168,236,.8);
                        outline: none;
                    }
                }

                input[type="submit"]{
                    background-color: ${colors.secondaryBlue2};
                    padding: 12px 20px;
                    color: #FFF;
                    font-weight: 600;
                    font-size: 18px;
                    border: unset;
                    box-shadow: 0px 4px 4px 0px #00000040;
                    transition: scale, linear 0.2s;
                    margin: auto;
                    display: block;

                    &:hover{
                        scale: 1.03;    
                    }
                }

                label{
                    color: ${colors.primaryBlue};
                    font-weight: 500;

                    .hs-form-required {
                        color: #f2545b;
                    }
                }

                .hs-error-msgs,.inputs-list{
                    padding: 0;
                    margin: 0;
                    
                    label{
                        color: #f2545b;
                        margin: 0;
                    }
                }

                .legal-consent-container {
                    .hs-form-booleancheckbox-display input {
                        margin: 3px 0;
                    }

                    .hs-dependent-field{
                        margin: 30px 0;
                    }
                }

                li{
                    list-style: none;
                }
            }

        }
    }

`
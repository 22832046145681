import React, {useState} from "react";
import InputField from "../CalculatorValueInput/CalculatorValueInput";
import FieldDescriptionPopup from "../FieldDescriptionPopup/FieldDescriptionPopup";
import { POPUP_DATA } from "../../data/popupDescriptions.data";

const SyncedSliders = ({currency}) => {

    const [totalVolume, setTotalVolume] = useState(1000000000);
    const [numOfTransactions, setNumOfTransactions] = useState(20000000);
    const [averageTransactionValue, setAverageTransactionValue] = useState(50);

    const totalVolumeChange = (volume) => {
        setTotalVolume(volume);
        updateSyncedSliders(volume, numOfTransactions, averageTransactionValue, "volume");
    };

    const numOfTransactionsChange = (transactions) => {
        setNumOfTransactions(transactions);
        updateSyncedSliders(totalVolume, transactions, averageTransactionValue, "transactions");
    };

    const averageTransactionValueChange = (averageValue) => {
        setAverageTransactionValue(averageValue);
        updateSyncedSliders(totalVolume, numOfTransactions, averageValue, "averageValue");
    };

    function updateSyncedSliders(totalVolume, numOfTransactions, averageTransactionValue, updateTrigger) {
        if (updateTrigger === "volume" || updateTrigger === "transactions") {
            averageTransactionValue = (Math.round(totalVolume / (numOfTransactions || 1)) || 1);
            setAverageTransactionValue(averageTransactionValue);
        } else if (updateTrigger === "averageValue") {
            //if numOfTransactions equals 0 divide by one - this is to prevent the browser from crashing
            numOfTransactions = (Math.round(totalVolume / (averageTransactionValue || 1)) || 1);
            setNumOfTransactions(numOfTransactions);
        }

        return;
    }

    return(
        <div className="calculator-field-group">
            <div className="calculator-field-container">
                <div className="calculator-field-description-container">
                    <div className="calculator-field-label">
                        What is the total annualised value of card processing?
                    </div>
                    <FieldDescriptionPopup
                        content={POPUP_DATA["total-volume"]}
                    />    
                </div>
                <InputField
                    minValue={100000000}
                    maxValue={5000000000}
                    firstSliderBreakpoint={500000000}
                    startingValue={1000000000}
                    currency={currency}
                    sliderLabels={['100m', '1b', '2b', '3b', '4b', '5b']}
                    fieldId='totalVolume'
                    onValueChange={totalVolumeChange}
                    currentValue={totalVolume}
                    enforceLowerLimit={true}
                    enforceUpperLimit={false}
                />    
            </div>
            <div className="calculator-field-container">
                <div className="calculator-field-description-container">
                    <div className="calculator-field-label">
                        What is your total annualised number of transactions processed?
                    </div>
                    <FieldDescriptionPopup
                        content={POPUP_DATA["number-of-annual-transactions"]}
                    />    
                </div>
                <InputField
                    minValue={0}
                    maxValue={50000000}
                    firstSliderBreakpoint={5000000}
                    startingValue={20000000}
                    sliderLabels={['0', '10m', '20m', '30m', '40m', '50m']}
                    fieldId='numberOfAnnualTransactions'
                    onValueChange={numOfTransactionsChange}
                    currentValue={numOfTransactions}
                    numberFieldMaxValue={totalVolume}
                />
            </div>
            <div className="calculator-field-container">
                <div className="calculator-field-description-container">
                    <div className="calculator-field-label">
                        What is your average transaction value (ATV) per transaction of card payments?
                    </div>
                    <FieldDescriptionPopup
                        content={POPUP_DATA["average-transaction-value"]}
                    />    
                </div>
                <InputField
                    minValue={10}
                    maxValue={1000}
                    firstSliderBreakpoint={100}
                    startingValue={50}
                    currency={currency}
                    sliderLabels={['10', '200', '400', '600', '800', '1000']}
                    fieldId='averageTransactionValue'
                    onValueChange={averageTransactionValueChange}
                    currentValue={averageTransactionValue}
                    numberFieldMaxValue={totalVolume}
                />
            </div>
        </div>
    );
};

export default SyncedSliders;
import React from "react";
import {Col, Row, Container} from "react-bootstrap";
import { DisclaimerWrapper } from "./Disclaimer.styles";

const Disclaimer = () => {

    return  (
    <DisclaimerWrapper className="pt-0 py-5 my-5">
        <Container className="p-5">
            <p>
                <strong>Disclaimer:</strong> The cost reductions provided by this calculator are based on the information you have entered and certain assumptions. These figures are estimates only. More precise figures of the cost reductions and processing improvements available through use of the APEXX Payment Orchestration Platform and the impact this could have on your business can be provided with a complimentary review of your current payments ecosystem.
            </p>
            <p>
                In order to receive a more comprehensive review and proposal, please complete the form and one of our payment experts will be in touch shortly.
            </p>        
        </Container>
    </DisclaimerWrapper>
)};

export default Disclaimer;
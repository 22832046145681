// InputValuesContext.js
import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { stringToNumber, percentageOrCurrency, calculateTotals } from '../utils/helperFunctions';

const InputValuesContext = createContext();

export const useInputValues = () => useContext(InputValuesContext);

export default InputValuesContext;

export const InputValuesProvider = ({ children }) => {
  const [InputValues, setInputValues] = useState({});

  const updateInputValue = useCallback((id, value) => {
    setInputValues(prevValues => ({ ...prevValues, [id]: value }));
    
  }, []);

  const processAllValues = useCallback((event) => {
    calculateTotals(event);
  }, []);

  useEffect(() => {
    processAllValues(InputValues);
  }, [processAllValues,InputValues]);

  return (
    <InputValuesContext.Provider value={{ InputValues, updateInputValue }}>
      {children}
    </InputValuesContext.Provider>
  );
};

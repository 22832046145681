import React from "react"
import Layout from "../components/Global/Layout/Layout"
import SEO from "../components/Global/SEO/seo"
import HeroSection from "../components/ApexxCalculator/HeroSection/HeroSection"
import Disclaimer from "../components/ApexxCalculator/Disclaimer/Disclaimer"
import SavingsCalculator from "../components/ApexxCalculator/SavingsCalculator/SavingsCalculator"
import { InputValuesProvider } from "../components/ApexxCalculator/SavingsCalculator/InputValuesContext/InputValuesContext"


const BnplConnect = () => {
  return (
    <Layout showHelpSection={false}>
      <SEO page="apexx-calculator" />
      <HeroSection />
      <InputValuesProvider>
        <SavingsCalculator />
      </InputValuesProvider>
      <Disclaimer />
    </Layout>
  )
}

export default BnplConnect

import React, { useState, useRef, useEffect } from "react";
import { FieldDescriptionPopupWrapper } from "./FieldDescriptionPopup.styles";
import { useStaticQuery, graphql } from "gatsby"

const FieldDescriptionPopup = ({content}) => {
    const [isVisible, setIsVisible] = useState(false);
    const wrapperRef = useRef(null);

    let title = `lorem ipsum`;
    let description = `<p>Lorem ipsum dolor sit amet.</p><p>Sociis natoque penatibus et magnis.</p>`;
    
    if(content && content.description){
        description = content.description;
    }
    if(content && content.title){
        title = content.title;
    }

    const data = useStaticQuery(graphql`
    {
        allFile(
            filter: {
            relativeDirectory: { eq: "apexx_calculator" },
            extension: { eq: "svg" },
            name: { eq: "info" }
            }
        ) {
            edges {
            node {
                publicURL
            }
            }
        }
    }
  `)
    const infoImage = (<img src={data.allFile.edges[0].node.publicURL} alt="info-toggle" />);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    return (
        <FieldDescriptionPopupWrapper ref={wrapperRef} className="ml-auto">
            <div 
                className={`info-toggle ${isVisible ? 'active' : ''}`} 
                onClick={toggleVisibility}>
                    {infoImage}
            </div>
            {isVisible && (
                <div className="field-description">
                    <div className="description-header">{title}</div>
                    <div className="description-content" dangerouslySetInnerHTML={{ __html: description }}></div>
                </div>
            )}
        </FieldDescriptionPopupWrapper>
    );
};

export default FieldDescriptionPopup;

import styled from "styled-components";
import { colors } from "../../../../../utils/styles"

export const InputSliderWrapper = styled.div`

    padding-bottom: 20px;

    .slider-container{
        padding-top: 80px;

        @media (max-width: 575px) {
            padding-top: 25px;
        }

        input[type="range"]{
            appearance: none;
            background: transparent;
            cursor: pointer;
            width: 100%;
            height: 4px;
            position: absolute;
            bottom: 35px;

            @media (max-width: 575px) {
                width: calc(100% + 16px);
                margin-left: -8px;
            }

            /***** Chrome, Safari, Opera,, Edge Chromium and Firefox *****/
           /*  &::-webkit-slider-runnable-track, &::-moz-range-track {
                background-color: ${colors.secondaryBlue3};
                height: 4px;
            } */

            &::-moz-range-thumb{
                -webkit-appearance: none;
                appearance: none;
                background-color: ${colors.secondaryBlue3};
                height: 21px;
                width: 21px;
                border: 3px solid white;
                border-radius: 50%;
                filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
                box-sizing: border-box;
            }
        }

        //for some reason the &:: syntax didn't work above
        input[type="range"]::-webkit-slider-thumb{
            -webkit-appearance: none;
            appearance: none;
            background-color: ${colors.secondaryBlue3};
            height: 24px;
            width: 24px;
            border: 3px solid white;
            border-radius: 50%;
            filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25)); 
        }

        output{
            position: absolute;
            transform: translateX(-50%);
            top: 0;
            background-color: ${colors.secondaryBlue3};
            color: #FFF;
            padding: 4px 8px;
            border-radius: 8px;
            font-weight: 600;
            font-family: Poppins;

            @media (max-width: 575px) {
                display: none;
            }

            &::after{
                content: "";
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 8px solid ${colors.secondaryBlue3};
                height: 0;
                left: 50%;
                transform: translateX(-50%);
                position: absolute;
                bottom: -8px;
                width: 0;
            }
        }

        .visual-slider{
            position: relative;
            margin: 0px 12px;
            display: block;

            @media (max-width: 575px) {
                margin: unset;
            }

            .slider-breakpoints-container{
                display: flex;
                justify-content: space-between;
                position: absolute;
                width: 100%;
                bottom: 10px;

                .slider-breakpoint{
                    height: 5px;
                    width: 1px;
                    background-color: #66AED1;

                    &:nth-child(even){
                        height: 3px;
                    }
                }
            }

            .slider-breakpoints-labels-container{
                .slider-breakpoint-label{
                    position: absolute;
                    bottom: -24px;
                    transform: translate(-50%);
                    color: ${colors.primaryBlue};

                    &:last-child{
                        left: 100%;
                    }

                    @media (max-width: 575px) {
                        display: none;

                        &:first-child, &:last-child{
                            display: block;
                        }

                        &:first-child, &:last-child{
                            transform: translate(-3px);
                        }
                        &:last-child{
                            transform: translate(-100%);
                        }
                    }
                }
            }

            .slider-track{
                width: 100%;
                height: 2px;
                background-color: #66AED1;
                position: absolute;
                bottom: 15px;
            }

            .slider-progress{
                width: 100%;
                height: 4px;
                background-color: ${colors.secondaryBlue3};
                position: absolute;
                bottom: 15px;
            }
        }
    }

`
import React, { useEffect, useRef, useState } from "react";
import { InputToggleWrapper } from "./InputToggle.styles";
import { useInputValues } from "../../InputValuesContext/InputValuesContext";

const InputToggle = ({fieldId = '', checked = false}) => {

    const [isChecked, setIsChecked] = useState(checked);
    const {updateInputValue} = useInputValues();
    const defaultValue = useRef(null);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const checkboxChange = (event) => {
        setIsChecked(event.target.checked);
        updateInputValue(fieldId, event.target.checked);
    };

    useEffect(() => {
        updateInputValue(fieldId, defaultValue.current.checked);
    }, []);

    return(
        <InputToggleWrapper>
            <input type="checkbox" name="" id={fieldId} ref={defaultValue} onChange={checkboxChange} checked={isChecked}/>
            <span></span>
        </InputToggleWrapper>
    );
}

export default InputToggle;
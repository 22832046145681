import React, {useState, useEffect} from "react";
import { InputSliderWrapper } from "./InputSlider.styles"
import { percentageOrCurrency, generateSliderBreakpoints, generateBreakpointLabels, calculateSliderProgress } from "../../utils/helperFunctions"

const InputSlider = ({
    minValue, 
    maxValue, 
    startingValue = false,
    currentValue = false,
    firstSliderBreakpoint = maxValue / 10,
    maxTicks = 100,
    currency = false,
    percentage = false,
    onValueChange,
    sliderLabels = false
}) => {
    
    // State to manage the slider value
    const [sliderValue, setSliderValue] = useState(percentageOrCurrency(maxValue / 2, currency, percentage, maxValue)); // Initial value set to 50
    const [sliderProgress, setSliderProgress] = useState(maxTicks/2); // Initial value set to minValue
    const [sliderTrackProgress, setsliderTrackProgress] = useState(maxTicks/2); // Initial value set to minValue
    const sliderTicksFirstBreakpoint = (maxTicks / 10);
    const increment = (maxValue - firstSliderBreakpoint) / (maxTicks - sliderTicksFirstBreakpoint);
    
    //if the min value isn't 0 the first 10% of the slider will need to increment on a smaller interval than the remaining 90%
    let startingIncrement = false;
    if (minValue != 0) {
        startingIncrement = (firstSliderBreakpoint - minValue) / sliderTicksFirstBreakpoint;
    }

    const calculateSliderValuePercentage = (newValue) => {
        setSliderProgress(newValue);
        setsliderTrackProgress(newValue * (100 / maxTicks));
    };

    useEffect(() => {
        let prefix = '';
        let suffix = '';
        if (currentValue || currentValue === 0) {// used to change value if other components state changes affects triggers an update
            if (currentValue < minValue) {
                prefix = '< ';
                currentValue = minValue;
            } else if (currentValue > maxValue) {
                suffix = '+';
                currentValue = maxValue;
            }

            const returnPercentage = calculateSliderProgress(currentValue, minValue, maxValue, maxTicks, firstSliderBreakpoint, sliderTicksFirstBreakpoint);
            calculateSliderValuePercentage(returnPercentage);
            setSliderValue(prefix + percentageOrCurrency(currentValue, currency, percentage, maxValue) + suffix);
        }
        else if (sliderValue){//this is used to change the value if the currency gets updates
            setSliderValue(prefix + percentageOrCurrency(sliderValue, currency, percentage, maxValue) + suffix);
        }
        return;
    });

    useEffect(() => {
        if (startingValue) {
            const sliderStartingPosition = calculateSliderProgress(startingValue, minValue, maxValue, maxTicks, firstSliderBreakpoint, sliderTicksFirstBreakpoint);
            const startingValueString = percentageOrCurrency(startingValue, currency, percentage, maxValue);
            setSliderValue(startingValueString);
            calculateSliderValuePercentage(sliderStartingPosition);
        }
        return;
    },[]);

    // Function to handle slider value change
    const handleSliderChange = (event) => {
        const sliderProgress = event.target.value;
        let sliderValue = firstSliderBreakpoint + (increment * (sliderProgress - sliderTicksFirstBreakpoint));
        if (sliderProgress === 0) {
            sliderValue = minValue;
        } else if (startingIncrement && sliderProgress <= sliderTicksFirstBreakpoint){
            sliderValue = (startingIncrement * sliderProgress) + minValue;
        }
        onValueChange(sliderValue);
        sliderValue = percentageOrCurrency(sliderValue, currency, percentage, maxValue);
        setSliderValue(sliderValue);
        calculateSliderValuePercentage(sliderProgress);
    };

    const sliderBreakpoints = generateSliderBreakpoints(11);
    const sliderBreakpointLabels = generateBreakpointLabels(sliderLabels, percentage, currency);

    return (
        <InputSliderWrapper style={{ position: 'relative' }}>
            <div className="slider-container">
                <div className="visual-slider">
                    <div className="slider-track"></div>
                    <div className="slider-progress"
                        style={{
                            clipPath: `polygon(0 0,${sliderTrackProgress}% 0,${sliderTrackProgress}% 100%,0 100%)`
                        }}
                    >
                    </div>
                    <div className="slider-breakpoints-container">
                        { sliderBreakpoints }
                    </div>
                    <div className="slider-breakpoints-labels-container">
                        { sliderBreakpointLabels }
                    </div>
                </div>
                <input 
                    type="range" 
                    min="0" 
                    max={maxTicks} 
                    value={sliderProgress} 
                    onChange={handleSliderChange} 
                />
                <output 
                    style={{
                        left: `calc(${sliderTrackProgress}% - ${12*((sliderTrackProgress * 2)-100)/100}px)`, // Adjust left position dynamically
                    }}
                >
                    {sliderValue}
                </output>
            </div>
        </InputSliderWrapper>
    )
};

export default InputSlider;
import styled from "styled-components";
import { colors } from "../../../../../utils/styles"

export const CalculatorValueInputWrapper = styled.div`

    display: grid;
    grid-template-columns: 1fr 150px;
    gap: 28px;
    padding-left: 14px;

    @media (max-width: 575px) {
        grid-template-columns: 1fr;
        padding-left: unset;
    }

    .slider-text-input-field-container{
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        max-width: 175px;

        @media (max-width: 575px) {
            margin-left: auto;
        }

        input.text-input-field{
            width: 100%;
            height: 34px;
            background: #FFF;
            color: ${colors.primaryBlue}; 
            font-weight: 600;
            padding: 4px 8px;
            border-radius: 8px;
            border: 1px solid #CECECE;
            box-shadow: 0px 3px 5px 0px rgba(20, 64, 86, 0.25);
            text-align: center;
        }

        &.input-error{
            input{
                border: 1px solid #f2545b;
                color: #f2545b;
            }
            .field-status-message{
                display: block;
                color: #f2545b
            }
        }

        .field-status-message{
            display: none;
            margin-bottom: 5px;
            font-size: 14px;
        }
    }

`
export const POPUP_DATA = {
    "total-volume" : {
        title: "What is the total annualised value of card processing?",
        description: "Your total annualised volume on cards refers to the total amount of money processed through card transactions (credit and debit cards) over a year. This includes all sales, refunds, and adjustments made through card payments. Calculating this figure provides insight into the scale of your card processing needs, helping us tailor our services to maximise your cost savings and efficiency in payment processing. Enter your estimated total or last year's actual volume to see how our Payment Orchestration solutions can optimise your costs."
    },
    "number-of-annual-transactions" : {
        title: "What is your total annualised number of transactions processed?",
        description: "<p>The total annualised number of transactions processed refers to the cumulative count of all individual transactions your business has processed over a year, on cards. This figure encompasses every sale, refund, chargeback, and any other type of transaction that has been completed.</p><p>Knowing the total number of transactions helps in assessing the volume of your payment operations and is crucial for optimising payment processing fees and enhancing transaction efficiency. Please input your total transactions from the past year or an estimate for the current year.</p>"
    },
    "average-transaction-value" : {
        title: "What is your average transaction value (ATV) per transaction of card payments?",
        description: "Your Average Transaction Value (ATV) per transaction of card payments is the average amount of money processed for each card transaction you conduct. This figure is calculated by dividing the total value of all card transactions (credit and debit cards) by the total number of card transactions over a specific period, typically a year."
    },
    "ecommerce-split" : {
        title: "What is the split of eCommerce vs face to face payments that you process?",
        description: "The split of eCommerce vs. face-to-face payments you process refers to the proportion of your transactions that are completed online versus those conducted in a physical location, such as a retail store or office. Please provide an estimate or actual data on your split of eCommerce versus face-to-face payments."
    },
    "travel-mmc" : {
        title: "Do you process card payments with a Travel Merchant Category Code?",
        description: "Processing card payments with a Travel Merchant Category Code (MCC) refers to transactions made within the travel industry, such as airlines, hotels, rental services, and travel agencies, which are categorised under specific codes by credit card networks. This categorisation can affect the fees and security measures associated with processing these payments."
    },
    "acceptance-rate" : {
        title: "What is your Acceptance Rate?",
        description: "Your Payment Acceptance Rate is a critical metric that represents the percentage of transactions successfully processed and accepted out of the total attempted transactions. This rate highlights the efficiency and reliability of your payment processing system, indicating how often your customers can complete their purchases without facing issues like declines or errors."
    },
    "more-than-one-acquirer" : {
        title: "Do you use multiple acquirers for card payments?",
        description: "Utilising multiple acquirers for card payments refers to the strategy of partnering with more than one bank or financial institution to process your card transactions. Indicate whether you currently employ multiple acquirers for your card payments."
    },

};
import React from "react";
import {Col, Row, Container} from "react-bootstrap";
import { HeroSectionWrapper } from "./HeroSection.styles";

const HeroSection = () => {

    return  (
    <HeroSectionWrapper className="pt-0 pt-md-5 pt-1 pb-5">
        <Container className="mb-5 px-3 text-center my-5">
            <h1 className="ambit font-weight-bold primary-blue-text d-block mb-4">Payment Processing Cost Saving Calculator</h1>
            <p className="intro-text my-4">Effective cost routing through the APEXX Payment Orchestration Platform can deliver significant savings on your annual payments costs. Find out how much extra your business is paying via the cost saving calculator below: </p>
        </Container>
    </HeroSectionWrapper>
)};

export default HeroSection;
import styled from "styled-components";
import { colors } from "../../../../../utils/styles"

export const InputToggleWrapper = styled.div`
    background-color: #A5A5A5;
    width: 80px;
    height: 32px;
    border-radius: 16px;
    position: relative;

    input[type="checkbox"]{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: unset;
        opacity: 0;
        position: relative;
        z-index: 2;

        &:hover{
            cursor: pointer;
        }
        
        &:checked + span {
            background-color: ${colors.secondaryBlue3};

            &::after{
                left: 52px;
            }

            &::before{
                content: "Yes";
                left: 14px;
            }
        }
    }

    span{
        background-color: #A5A5A5;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        border-radius: 16px;
        z-index: 1;
        transition: background-color 0.25s linear;

        &::after{
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background-color: white;
            position: absolute;
            top: 4px;
            left: 4px;
            transition: left 0.25s linear;
            filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
        }
        
        &::before{
            content: "No";
            color: #FFF;
            font-weight: 600;
            font-size: 14px;
            font-family: 'Poppins';
            position: absolute;
            left: 46px;
            top: 5px;
            transition: left 0.25s linear;
        }
        
    }

`
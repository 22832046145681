import React, {useState, useRef, useEffect} from "react";
import InputSlider from "../InputSlider/InputSlider"
import { CalculatorValueInputWrapper } from "./CalculatorValueInput.styles";
import {percentageOrCurrency, stringToNumber, textInputEvent} from "../../utils/helperFunctions"
import { useInputValues } from "../../InputValuesContext/InputValuesContext";

const CalculatorValueInput = ({
  minValue,
  maxValue, 
  startingValue = false,
  firstSliderBreakpoint = maxValue / 10,
  maxTicks = 100,
  currency = false, 
  percentage = false,
  sliderLabels = [],
  fieldId = '',
  onValueChange = () => {},
  currentValue = false,
  enforceLowerLimit = true,
  enforceUpperLimit = true,
  numberFieldMaxValue = false
}) => {

    const [inputValue, setInputValue] = useState('');
    const [sliderValue, setSliderValue] = useState();
    const [currentFieldValue, setCurrentFieldValue] = useState();
    const {updateInputValue} = useInputValues();

    useEffect(() => {
      if (currentValue || currentValue === 0) {
        setInputValue(currentValue);
        setSliderValue(currentValue);
        setCurrentFieldValue(currentValue);
      }
    });

    const inputSliderChange = (newValue) => {
        setInputValue(newValue);
        setSliderValue(false);
        setCurrentFieldValue(stringToNumber(newValue));
        onValueChange(newValue);
        //updateInputValue(fieldId, newValue);
    };
    const inputFieldChange = (newValue) => {
        setInputValue(newValue);
        if (enforceUpperLimit && stringToNumber(newValue) > maxValue) {
          setSliderValue(stringToNumber(maxValue));
          setCurrentFieldValue(stringToNumber(maxValue));  
        } else if (enforceLowerLimit && stringToNumber(newValue) < minValue) {
          setSliderValue(stringToNumber(minValue));
          setCurrentFieldValue(stringToNumber(minValue));  
        } else{
          setSliderValue(stringToNumber(newValue));
          setCurrentFieldValue(stringToNumber(newValue));  
        }
        onValueChange(stringToNumber(newValue));
    };

    useEffect(() => {
      if (currentFieldValue) {
        updateInputValue(fieldId, currentFieldValue);
      } else if (startingValue) {
        updateInputValue(fieldId, startingValue);
      }
    }, [currentFieldValue]);

    return(
        <CalculatorValueInputWrapper>
            <InputSlider
                minValue={minValue}
                maxValue={maxValue}
                startingValue={startingValue}
                currentValue={sliderValue}
                firstSliderBreakpoint={firstSliderBreakpoint}
                maxTicks={maxTicks}
                currency={currency}
                percentage={percentage}
                onValueChange={inputSliderChange}
                sliderLabels={sliderLabels}
            />
            <InputField 
                minValue={minValue}
                maxValue={maxValue}
                startingValue={startingValue}
                currentValue={inputValue}
                currency={currency}
                percentage={percentage}
                onValueChange={inputFieldChange}
                enforceLowerLimit={enforceLowerLimit}
                enforceUpperLimit={enforceUpperLimit}
                numberFieldMaxValue={numberFieldMaxValue}
            />
            <input type="hidden" name="" id={fieldId} value={currentFieldValue}/>
        </CalculatorValueInputWrapper>
    )
};

export default CalculatorValueInput;

const InputField = ({
  minValue,
  maxValue, 
  startingValue = false, 
  currentValue = false, 
  onValueChange, 
  currency = false, 
  percentage = false,
  enforceLowerLimit = true,
  enforceUpperLimit = true,
  numberFieldMaxValue = false
}) => {

    const [inputError, setInputError] = useState(false);
    const inputRef = useRef(null);

    maxValue = numberFieldMaxValue || maxValue;

    if (enforceUpperLimit && numberFieldMaxValue === 0) {
      onValueChange(0);
    } else if (enforceUpperLimit && currentValue > maxValue) {
      onValueChange(maxValue);
    }

    function moveCursorToEnd(){
      if (percentage) {
        if (inputRef.current.selectionStart >= inputRef.current.value.length) {
          const cursorPosition = inputRef.current.value.length - 1;
          inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }
      }
    }

    let formattedValue;
    if (currentValue || currentValue === 0) {
      formattedValue = percentageOrCurrency(currentValue, currency, percentage);
    } else {
      formattedValue = percentageOrCurrency(startingValue, currency, percentage, maxValue);
    }

    const focusOff = () => {
      if (enforceUpperLimit) {
        if(stringToNumber(currentValue) > maxValue){
          onValueChange(maxValue);
        }
      }
      if (enforceLowerLimit) {
        if (stringToNumber(currentValue) < minValue) {
          onValueChange(minValue);
        }
      }
      setInputError(false);
    };

    const handleChange = (event) => {
      const valueAsNumber = stringToNumber(event.target.value);
      const previousValue = percentageOrCurrency(currentValue, currency, percentage);

      setInputError(false);
      if (enforceUpperLimit && valueAsNumber > maxValue) {
        setInputError(true);
      } else if (enforceLowerLimit && valueAsNumber < minValue) {
        setInputError(true);
      }
      onValueChange(textInputEvent(event, currency, percentage, previousValue));
    };

    return (
      <div className={`slider-text-input-field-container ${inputError ? 'input-error' : ''}`}>
        <input 
            className="text-input-field"
            type="text"
            value={formattedValue}
            placeholder="Enter value"
            onClick={moveCursorToEnd}
            onChange={handleChange}
            ref={inputRef}
            onBlur={focusOff}
            inputmode="numeric"
        />
      </div>
    );
};

import styled from "styled-components";
import { colors } from "../../../../../utils/styles"


export const CurrencySelectorWrapper = styled.div`
    display: flex;
    gap: 40px;

    @media screen and (max-width: 1201px) {
        display: grid;
        gap: 20px;
    }

    .select-currency-text{
        font-weight: 600;
        color: ${colors.primaryBlue};;
    }

    .current-options{
        display: flex;
        gap: 40px;
        align-items: center;

        .currency-option{
            display: flex;
            align-items: center;
            flex-direction: row;
            cursor: pointer;

            label{
                padding-left: 10px;
                margin-bottom: unset;
                color: ${colors.primaryBlue};
                font-weight: 600;
                cursor: pointer;
            }

            input[type="radio"]{
                appearance: none;
                height: 18px;
                width: 18px;
                background-color: #FFF;
                border: 1px solid #CECECE;
                border-radius: 50%;
                position: relative;
                cursor: pointer;

                &::before {
                    content: "";
                    width: 12px;
                    height: 12px;
                    background-color: ${colors.secondaryBlue2};
                    display: block;
                    border-radius: 50%;
                    transform: scale(0);
                    transition: 120ms transform ease-in-out;
                    box-shadow: inset 1em 1em var(--form-control-color);
                    position: absolute;
                    top: 2px;
                    left: 2px;
                }

                &:checked::before {
                    transform: scale(1);
                }
            }
        }
    }
`
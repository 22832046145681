import React from "react";

export function percentageOrCurrency(currentValue, currency, percentage, maxValue = 99999999999999){
    currentValue = reduceToCeiling(currentValue, maxValue);
    if (percentage) {
        return numberToString(currentValue) + '%';
    } else if (currency) {
        return currency + numberToString(currentValue);
    }
    return numberToString(currentValue);
};

export function numberToString(value){
  if (typeof value != 'number') {
    value = value.replace(/[^\d.]/g, '');
  }

  //set upper limit
  if (isNaN(value)) {
      return;
  }

  // Add commas as thousand separators
  let returnString = value.toString().split('.');
  returnString = returnString[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Return the formatted currency value with the currency symbol
  return returnString;
}

export function stringToNumber(value){
    if (typeof value === 'number') {
        return value;
    }
    return Number(value.replace(/\D/g, ''));
}

export function generateSliderBreakpoints(iterationCount){

    const elements = [];
    for (let i = 0; i < iterationCount; i++) {
        elements.push(<div className="slider-breakpoint"></div>);
    }

    return elements;
}

export function generateBreakpointLabels(sliderLabels, percentage, currency){
    const sliderLabelsLength = sliderLabels.length - 1;
    const labelsArray = [];

    sliderLabels.forEach((element, index) => {
        const offsetPercentage = (index / sliderLabelsLength) * 100;
        if (percentage) {
            element =  element + '%';
        } else if (currency) {
            element =  currency + element;
        }

        labelsArray.push(
        <div 
            className={"slider-breakpoint-label"}
            style={{
                left: `calc(${offsetPercentage}%)`, // Adjust left position dynamically
            }}
        >
            {element}
        </div>);
    });

    return labelsArray;
}

export function textInputEvent(event, currency, percentage, previousValue = false){
    const cursorPosition = event.target.selectionStart;  // Get the current cursor position
    const originalLength = event.target.value.length;
    let newValue = event.target.value;
    if (stringToNumber(previousValue) === stringToNumber(event.target.value) && previousValue[cursorPosition] === ',') {
        newValue = event.target.value.slice(0, cursorPosition - 1) + event.target.value.slice(cursorPosition);
    }
    event.target.value = percentageOrCurrency(newValue, currency, percentage);

    // Calculate the new cursor position and set it
    const newLength = event.target.value.length;
    event.target.setSelectionRange(cursorPosition + (newLength - originalLength), cursorPosition + (newLength - originalLength));

    return event.target.value;
};


export function reduceToCeiling(value, ceiling) {
    value = stringToNumber(value);
    if (value > ceiling) {
        while (value > ceiling) {
            value = Math.floor(value / 10);
        }
    }
    return value;
}

export function calculateSliderProgress(currentValue, minValue, maxValue, maxTicks, firstSliderBreakpoint, sliderTicksFirstBreakpoint){
    let progressValue = Math.floor(((currentValue / maxValue) * maxTicks) / (100 / maxTicks));
    if (maxValue - minValue < 100) {
        const target = currentValue - minValue;
        const upperBound = maxValue - minValue;
        progressValue = Math.floor((target/upperBound) * maxTicks);
    }
    if (currentValue <= minValue) {
        progressValue = 0;
    } else if (currentValue >= maxValue) {
        progressValue = maxTicks;
    } 
    else if (currentValue < firstSliderBreakpoint) {
        progressValue = Math.floor((currentValue / firstSliderBreakpoint) * sliderTicksFirstBreakpoint);
    }
    return progressValue;
}


export function calculateTotals(event){

    //get values from input fields
    const annualValue = event.totalVolume;
    const travelBool = event.travelMmc;
    const moreThanOneAcquirerBool = event.moreThanOneAcquirer;
  
    //work out percentage values
    const ecomSplitPercentage = event.ecomSplit * 0.01;
    const acceptanceRatePercentage = event.acceptanceRate * 0.01;
    const currency = event.currency;

    let currencyField = '';
    if (currency === "$") {
        currencyField = 'USD';
    } else if(currency === "€") {
        currencyField = 'EUR';
    } else if(currency === "£") {
        currencyField = 'GBP';
    }
  
    //work out totals
    const costRouting = costRoutingCalc(travelBool, moreThanOneAcquirerBool, annualValue, ecomSplitPercentage, acceptanceRatePercentage);
    const revenueImprovement = revenueImprovementCalc(moreThanOneAcquirerBool, annualValue, ecomSplitPercentage, acceptanceRatePercentage);
    const total = Math.round(costRouting + revenueImprovement);
    const revenueSaved = ((total / annualValue) * 100);

    document.querySelector('.cost-savings .summary-value').innerHTML = percentageOrCurrency(costRouting, currency);
    document.querySelector('.total-improvement .summary-value').innerHTML = percentageOrCurrency(total, currency);
    document.querySelector('.revenue-improvement .summary-value').innerHTML = percentageOrCurrency(revenueImprovement, currency);
    document.querySelectorAll('.totals-summary .summary-total').forEach(element => {
        const currentTotal = stringToNumber(element.innerHTML);
        element.innerHTML = percentageOrCurrency(total, currency);
        if (currentTotal !== total) {
            element.classList.add('scale-total');
            document.querySelector('.mobile-totals-container').classList.add('scale-total');
            setTimeout(() => {
                element.classList.remove('scale-total');
                document.querySelector('.mobile-totals-container').classList.remove('scale-total');
            }, 300);
        }
    });
    document.querySelectorAll('.totals-summary .percentage-improvement').forEach(element => {
        const currentPercentage = stringToNumber(element.innerHTML);
        element.innerHTML = revenueSaved.toFixed(2) + '%';
        element.classList.add('scale-total');
        if ((currentPercentage/100).toFixed(2) !== revenueSaved.toFixed(2)) {
            document.querySelector('.mobile-totals-container').classList.add('scale-total');
            setTimeout(() => {
                element.classList.remove('scale-total');
                document.querySelector('.mobile-totals-container').classList.remove('scale-total');
            }, 300);
        }
    });
     
    const acceptanceRateField = document.querySelector("[name='csc_acceptance_rate']");
    const annualPaymentsValueField = document.querySelector("[name='csc_annual_payments_value']");
    const cscAnnualPaymentsVolumeField = document.querySelector("[name='csc_annual_payments_volume']");
    const cscAverageTransactionValueField = document.querySelector("[name='csc_average_transaction_value']");
    const cscCurrencyField = document.querySelector("[name='csc_currency']");
    const cscEcommerceSplitField = document.querySelector("[name='csc_ecommerce_split']");
    const cscMoreThan1AcquirerField = document.querySelector("[name='csc_more_than_1_acquirer']");
    const cscTravelMccField = document.querySelector("[name='csc___travel_mcc']");

    if (acceptanceRateField) {
        acceptanceRateField.value = acceptanceRatePercentage;
    }
    if (annualPaymentsValueField) {
        annualPaymentsValueField.value = annualValue;
    }
    if (cscAnnualPaymentsVolumeField) {
        cscAnnualPaymentsVolumeField.value = event.numberOfAnnualTransactions;
    }
    if (cscAverageTransactionValueField) {
        cscAverageTransactionValueField.value = event.averageTransactionValue;
    }
    if (cscCurrencyField) {
        cscCurrencyField.value = currencyField;
    }
    if (cscEcommerceSplitField) {
        cscEcommerceSplitField.value = ecomSplitPercentage;
    }
    if (cscMoreThan1AcquirerField) {
        cscMoreThan1AcquirerField.value = moreThanOneAcquirerBool;
    }
    if (cscTravelMccField) {
        cscTravelMccField.value = travelBool;
    }
    
    return;
}

function costRoutingCalc(travel, moreThanOneAcquirer, annualValue, ecomSplitPercentage, acceptanceRatePercentage){
        
    const basicCalculation = (annualValue * ecomSplitPercentage * acceptanceRatePercentage) * 0.01;

    if (travel) {
        if (moreThanOneAcquirer) {
            return basicCalculation * 0.06;
        }
        return basicCalculation * 0.05;
    } else{
        if (moreThanOneAcquirer) {
            return basicCalculation * 0.02;
        }
        return basicCalculation * 0.01;
    }
}

function revenueImprovementCalc(moreThanOneAcquirer, annualValue, ecomSplitPercentage, acceptanceRatePercentage){
    let calculationPercentage = 0.02;
    
    if (moreThanOneAcquirer) {
        calculationPercentage = 0.05;
    }
    
    if (acceptanceRatePercentage >= 0.88) {
        return ((annualValue * ecomSplitPercentage) * calculationPercentage);
    } else {
        return ((0.88 - acceptanceRatePercentage) * annualValue * ecomSplitPercentage) + ((annualValue * ecomSplitPercentage) * calculationPercentage);
    }
}